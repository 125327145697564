import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { PFPayoffCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const CreditCardPayoffPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, personalFinancePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('CreditCardPayoffPage.subTitle', {
        defaultValue: 'When will I pay off my credit card balances?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('CreditCardPayoffPage.textContent', {
                  defaultValue:
                    "When you're chipping away at a credit card balance, paying a little more each month could go a long way. Use this calculator to see how increasing your monthly payments could shorten your time to pay the balance off and reduce the amount you pay in interest.",
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: personalFinancePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('CreditCardPayoffPage.pageTitle', {
              defaultValue: 'Credit Card Payoff Calculator',
            })}
            pathname="/calculators/personal-finance/credit-card-payoff"
          />
          <CalculatorSectionModule
            pageTitle={t('CreditCardPayoffPage.title', {
              defaultValue: 'Personal Finance Calculators',
            })}
            sections={pageData}
            calculator={<PFPayoffCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

CreditCardPayoffPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { CreditCardPayoffPage }
export default CreditCardPayoffPage
